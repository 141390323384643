import React, { useState, useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import service from "../../../service";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import { Button, Modal, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./NewEdition.scss";

export default function NewEdition() {
  const navigate = useNavigate();
  const [quill, setQuill] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [year, setYear] = useState("");
  const [photos, setPhotos] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edition, setEdition] = useState({ id: 'id da edição' }); // Adicione a edição aqui conforme necessário

  useEffect(() => {
    const quillInstance = new Quill("#editor-container", {
      theme: "snow",
      placeholder: "Texto vai aqui",
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          ["link", "image", "video", "formula"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["clean"],
        ],
      },
    });

    setQuill(quillInstance);
  }, []);

  const saveNewEdition = async () => {
    if (!quill) return;

    setIsLoading(true);

    const newEdition = {
      title,
      year,
      content: JSON.stringify(quill.getContents()),
      innerHTML: quill.root.innerHTML,
      pureText: quill.getText(),
      photoIds: photos,
    };

    try {
      await service.createEdition(newEdition, sessionStorage.getItem("token"));
      alert(`Nova edição do ano ${newEdition.year} criada com sucesso!`);
      navigate("/admin/manage-editions");
    } catch (err) {
      console.error("Erro ao criar edição:", err);
      alert("Erro ao criar edição: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setIsModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const confirmUpload = async () => {
    if (!selectedFile) return;
    const formData = new FormData();
    formData.append("photos", selectedFile);
    formData.append("author", "Nome do Fotógrafo");

    try {
      await service.uploadPhoto(
        edition.id,
        formData,
        sessionStorage.getItem("token")
      );
      alert("Foto enviada com sucesso!");
      setSelectedFile(null);
      setPreview(null);
      setIsModalOpen(false);
      const updatedEdition = await service.getEditionById(edition.id);
      setPhotos(updatedEdition.photoIds || []);
    } catch (err) {
      console.error("Erro ao enviar foto:", err);
      alert("Erro ao enviar foto: " + err.message);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPreview(null);
    setSelectedFile(null);
  };

  return (
    <div className="page-container">
      <DashboardHeader />
      <div className="new-edition">
        <h1 className="title">Criar Nova Edição</h1>
        <form noValidate autoComplete="off">
          <div className="input-wrapper">
            <label htmlFor="title">Título</label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="year">Ano</label>
            <input
              id="year"
              type="number"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
          </div>
          <div id="editor-container" className="editor" />

          <div className="photo-section">
            <h3 className="photos-title">Fotos</h3>
            <div className="photo-gallery">
              {photos.map((photoId, index) => (
                <div key={index} className="photo-thumbnail">
                  <img
                    src={`${service.getPhotoUrl(photoId)}`}
                    alt={`Foto ${photoId}`}
                  />
                </div>
              ))}
              <label className="photo-thumbnail add-photo">
                <input type="file" onChange={handleFileChange} />
                <AddIcon />
              </label>
            </div>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={saveNewEdition}
            disabled={isLoading}
            className="save-button"
          >
            {isLoading ? "Salvando..." : "Salvar"}
          </Button>
        </form>
      </div>

      {/* Modal para confirmação de upload */}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="modal-box">
          <h2 id="modal-title">Confirmar Upload</h2>
          {preview && (
            <div className="preview-container">
              <img
                src={preview}
                alt="Pré-visualização"
                className="preview-image"
              />
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={confirmUpload}
            className="confirm-button"
          >
            Confirmar Upload
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={closeModal}
            className="cancel-button"
          >
            Cancelar
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

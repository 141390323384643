import React, { useMemo } from "react";
import "./mobilePixels.scss";

const colors = ["#e74c3c", "#3498db", "#2ecc71", "#f1c40f", "#9b59b6"];

const MobileAnimatedPixels = ({ amount = 100 }) => {
  const pixelData = useMemo(() => {
    return Array.from({ length: amount }, (_, index) => {
      const animationDelay = Math.random() * 3;
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      return {
        id: index,
        left: index,  // ou Math.random()*100 se quiser randomizar posição
        animationDelay,
        randomColor,
      };
    });
  }, [amount]);

  return (
    <div className="pixels-container-mobile">
      {pixelData.map(({ id, left, animationDelay, randomColor }) => (
        <div
          key={id}
          className="pixel"
          style={{
            left: `${left}%`,
            bottom: "0px",
            backgroundColor: randomColor,
            animationDelay: `${animationDelay}s`,
          }}
        ></div>
      ))}
    </div>
  );
};

export default MobileAnimatedPixels;

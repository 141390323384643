import React from "react";
import { Route, Navigate } from "react-router-dom";

const PrivateRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = !!sessionStorage.getItem("token");

  return isAuthenticated ? <Element /> : <Navigate to="/admin" />;
};

export default PrivateRoute;

// Dashboard.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DashboardHeader.scss";
import logo from "../../Assets/logo-ecofoto.png";
import { FaBars, FaTimes } from "react-icons/fa"; // Importa os ícones do menu hambúrguer

export default function DashboardHeader() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); // Estado para controlar o menu

  const logout = () => {
    sessionStorage.removeItem("token");
    navigate("/admin");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="dashboard-container">
      <div className="logo-and-menu">
        <img
          src={logo}
          alt="Logo"
          className="dashboard-logo"
          onClick={() => navigate("/admin/home")}
        />
        <button className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>
      <div className={`navigation-buttons ${menuOpen ? "active" : ""}`}>
        <button
          className="dashboard-button"
          onClick={() => {
            navigate("/admin/home");
            setMenuOpen(false);
          }}
        >
          Home
        </button>
        <button
          className="dashboard-button"
          onClick={() => {
            navigate("/admin/editor");
            setMenuOpen(false);
          }}
        >
          Editar Textos
        </button>
        <button
          className="dashboard-button"
          onClick={() => {
            navigate("/admin/manage-editions");
            setMenuOpen(false);
          }}
        >
          Edições
        </button>
        <button
          className="dashboard-button"
          onClick={() => {
            navigate("/admin/register");
            setMenuOpen(false);
          }}
        >
          Adicionar Moderadores
        </button>
        <button
          className="dashboard-button"
          onClick={() => {
            navigate("/admin/users");
            setMenuOpen(false);
          }}
        >
          Gerenciar Usuários
        </button>
        <button
          className="dashboard-button logout-button"
          onClick={() => {
            logout();
            setMenuOpen(false);
          }}
        >
          Sair
        </button>
      </div>
    </div>
  );
}

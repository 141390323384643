import "./Sidebar-Direita.scss";
import React, { useState, useEffect, useRef } from "react";
import service from "../../service.js"; // Certifique-se de importar o serviço para chamar a API

function SidebarDireitaIcons(props) {
  const [liga, setLiga] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  
  const leaveTimeout = useRef(null);

  const handleMouseLeave = () => {
    if (leaveTimeout.current) {
      clearTimeout(leaveTimeout.current);
    }
    leaveTimeout.current = setTimeout(() => {
      setLiga(false);
    }, 2000);
  };

  useEffect(() => {
    return () => {
      if (leaveTimeout.current) {
        clearTimeout(leaveTimeout.current);
      }
    };
  }, []);

  function openSearch() {
    props.setPesquisa(!props.pesquisa);
    setSearchValue("");
    document.getElementById("searcher").value = "";
  }

  function arrowScrolling() {
    if (props.bg === "var(--rosa)") {
      props.scrollToSection("seg", "down-1");
    } else if (props.bg === "var(--azul)") {
      props.scrollToSection("ter", "down-1");
    }
  }

  // Faz a busca quando o usuário pressiona "Enter"
  async function handleKeyDown(e) {
    if (e.key === "Enter") {
      props.setPlaceOn("pesquisa");
      props.setValue(searchValue.toLowerCase()); // Passa o valor da pesquisa via props
    }
  }

  function setPlace(section, position, pagina, color) {
    props.scrollToSection(section, pagina);
    props.setPosition(position);
    setTimeout(function () {
      props.setSecondaryIconColor("var(--" + color + ")");
    }, 2300);
  }

  return (
    <div className="sidenav-direita-icons">
      <div
        className={
          "sliding-sidebar-div me-pesquisa-div " +
          (props.pesquisa ? "me-pesquisa-on" : "me-pesquisa-off") +
          " border-" +
          props.bg.substring(6, props.bg.length - 1)
        }
      >
        <input
          id="searcher"
          name="searcher"
          type="text"
          onKeyDown={handleKeyDown} // Inicia a busca com Enter
          onChange={(e) => setSearchValue(e.target.value)} // Armazena o valor da pesquisa
        ></input>
      </div>
      <span
        onClick={openSearch}
        className={
          "icon icon-superior style-" +
          props.bg.substring(6, props.bg.length - 1)
        }
      ></span>
      {props.placeOn === "down-1" && (
        <div
          className={
            props.bg == "var(--azul)"
              ? "arrow-icon-black arrow-icon icon"
              : props.bg == "var(--rosa)"
              ? "arrow-icon icon"
              : ""
          }
          onClick={arrowScrolling}
        ></div>
      )}
      <span
        onClick={() => setPlace("ter", "me-liga", "pagina-vertical-3", "azul")}
        className={
          "icon icon-inferior style-" +
          props.bg.substring(6, props.bg.length - 1) +
          "-inf"
        }
        onMouseEnter={() => setLiga(true)}
        onMouseLeave={handleMouseLeave}
      ></span>
      <div
        onClick={() => setPlace("ter", "me-liga", "pagina-vertical-3", "azul")}
        className={
          "sliding-sidebar-div me-liga-div " +
          (liga ? "me-liga-on" : "me-liga-off") +
          " border-" +
          props.bg.substring(6, props.bg.length - 1)
        }
        onMouseEnter={() => setLiga(true)}
        onMouseLeave={handleMouseLeave}
      >
        <a href="#me-liga" className="no-underline">Me liga...</a>
      </div>
    </div>
  );
}

export default SidebarDireitaIcons;

import React, { useEffect, useRef, useState } from "react";
import SidebarDireita from "../Sidebar-Direita/Sidebar-Direita";
import ufrjlogo from "./Assets/UFRJlogo.png";
import camera from "./Assets/Camera.png";
import tv from "./Assets/Tv2.png";
import galera from "./Assets/galera.png";
import ciclo from "./Assets/ciclo.png";
import seta from "./Assets/seta-baixo.png";
import AnimatedPixels from "../AnimatedPixels/AnimatedPixels";
import MobileAnimatedPixels from "../MobilePixels/mobilePixels";
import Edition from "./edition";
import images from "./Assets/imageImports";
import titles from "./titles";
import service from "../../service";
import "./vertical.scss";

function Vertical1(props) {
  const [isEditionVisible, setIsEditionVisible] = useState(false);
  const [isInscriptionEnabled, setIsInscriptionEnabled] = useState(false);
  const elementRef = useRef(null);

  const [isMobile, setIsMobile] = useState(false);
  const [showPixels, setShowPixels] = useState(false); // novo estado

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchInscriptionStatus = async () => {
      try {
        const data = await service.getToggleInscription();
        setIsInscriptionEnabled(data.value);
      } catch (error) {
        console.error("Erro ao obter status de inscrição:", error);
      }
    };

    fetchInscriptionStatus();

    if (props.placeOn === "pagina-vertical-1") {
      setTimeout(() => {
        if (elementRef.current) {
          elementRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 1500);
    }
  }, [props.placeOn]);

  const toggleEditionVisibility = () => {
    setIsEditionVisible(!isEditionVisible);
  };

  const currentEditionData = {
    images: Array.from({ length: 39 }, (_, index) => ({
      small: images[`edit1_${index + 1}_small`],
      large: images[`edit1_${index + 1}_large`],
    })),
    titles: titles[1],
    description: `...`, // Texto omitido
    signatures: [
      {
        title: "Coordenação-geral...",
        name: "Camila Damico Medina (Setor de Extensão - UFRJ)",
      },
      // ... resto das assinaturas
    ],
  };

  const verticalPaddingRef = useRef(null);

  useEffect(() => {
    const verticalPaddingEl = verticalPaddingRef.current;
    if (!verticalPaddingEl) return;

    const onScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = verticalPaddingEl;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        // Quando chega ao final (usei -10 como margem de segurança)
        setShowPixels(true);
      } else {
        setShowPixels(false);
      }
    };

    verticalPaddingEl.addEventListener("scroll", onScroll);
    return () => {
      verticalPaddingEl.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div
      className={
        props.placeOn === "pagina-vertical-1"
          ? "vertical pagina-vertical-1 up"
          : "vertical pagina-vertical-1 down"
      }
      ref={elementRef}
    >
      <div className="area-seta-pra-baixo">
        <img
          src={seta}
          alt="seta para abaixar a tela"
          className="seta-pra-baixo"
        />
      </div>

      <div className="vertical-left hide-on-mobile">
        <div className="menu-vertical-left">
          <a href="#historia">_Sobre nós</a>
          <a href="#o-ciclo">_Ciclo de criação</a>
          <a href="#como-faz">_Como faz pra realizar tudo isso?</a>
          <a href="#nossa-galera">_Nossa galera</a>
        </div>
        <div
          className="seta-pro-topo"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });

            const scrollableElements = document.querySelectorAll("*");
            scrollableElements.forEach((element) => {
              if (element.scrollHeight > element.clientHeight) {
                element.scrollTo({ top: 0, behavior: "smooth" });
              }
            });
          }}
        ></div>
      </div>

      <div className="vertical-right">
        <div className="vertical-padding" ref={verticalPaddingRef}>
          {props.textinhos &&
            props.textinhos.map((t, i) => {
              if (t.sectionId === 1) {
                return (
                  <div key={i}>
                    <div
                      id={t.tagId}
                      style={{ color: "var(--roxo)" }}
                      className="vertical-title bg-branco"
                    >
                      {t.title}
                    </div>
                    <div>
                      {t.tagId === "sobre-nos" && (
                        <img
                          alt="UFRJ"
                          src={ufrjlogo}
                          className="float-right vertical-logo"
                        />
                      )}
                      {t.tagId === "o-ciclo" && (
                        <img
                          alt="ciclo"
                          src={ciclo}
                          className="float-right vertical-tv"
                        />
                      )}
                      {t.tagId === "missoes" && (
                        <img
                          alt="Câmera fotográfica"
                          src={camera}
                          className="float-right vertical-camera"
                        />
                      )}
                      {t.tagId === "como-faz" && (
                        <img
                          alt="Televisão"
                          src={tv}
                          className="vertical-tv float-left"
                        />
                      )}
                      {t.tagId === "nossa-galera" && (
                        <img
                          alt="galera"
                          src={galera}
                          className="float-right vertical-logo"
                        />
                      )}
                      <div
                        className="vertical-text"
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />

                      {t.tagId === "o-ciclo" && (
                        <div>
                          <button
                            className="edicao"
                            onClick={toggleEditionVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            {isEditionVisible
                              ? "Ocultar Edição Atual"
                              : isInscriptionEnabled
                                ? "Edição Atual - Inscreva-se aqui!"
                                : "Edição Atual"}
                          </button>
                          {isEditionVisible && (
                            <>
                              {isInscriptionEnabled ? (
                                <div>
                                  <p
                                    style={{ marginTop: "30px", fontSize: "28px" }}
                                    className="vertical-text"
                                  >
                                    As inscrições para a edição atual estão abertas
                                  </p>
                                  <a
                                    href="https://google.com.br"
                                    style={{
                                      marginTop: "30px",
                                      fontSize: "24px",
                                      display: "inline-block",
                                    }}
                                    className="vertical-link"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Clique aqui para se inscrever
                                  </a>
                                </div>
                              ) : (
                                <Edition
                                  edition="Edição Atual"
                                  images={currentEditionData.images}
                                  description={currentEditionData.description}
                                  signatures={currentEditionData.signatures}
                                  showBackButton={false}
                                />
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          <div
            className="seta-pro-topo-mobile"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });

              const scrollableElements = document.querySelectorAll("*");
              scrollableElements.forEach((element) => {
                if (element.scrollHeight > element.clientHeight) {
                  element.scrollTo({ top: 0, behavior: "smooth" });
                }
              });
            }}
          ></div>
          {/* Colocamos o componente de pixels no final do conteúdo */}
          {isMobile ? (
            <MobileAnimatedPixels amount={100} />
          ) : (
            <AnimatedPixels amount={100} />
          )}

        </div>
      </div>
      <SidebarDireita bg="var(--verde)" />
    </div>
  );
}

export default Vertical1;

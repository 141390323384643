import React, { useState, useEffect, useCallback } from "react";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import service from "../../../service";
import { useNavigate } from "react-router-dom";
import "./ManageEditions.scss";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ConfirmDialog from "../../../Components/ConfirmDialog/ConfirmDialog";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";

export default function ManageEditions() { 
  const [editions, setEditions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [editionToDelete, setEditionToDelete] = useState(null);
  const [error, setError] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "info",
    message: "",
  });
  const [isOpen, setIsOpen] = useState(false); 

  const navigate = useNavigate();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const openSnackbar = (severity, message) => {
    setSnackbar({ open: true, severity, message });
  };

  const fetchEditions = useCallback(async () => {
    setIsLoading(true);
    setError("");
    try {
      const token = sessionStorage.getItem("token");
      const editionsData = await service.getAllEditions(token);
      setEditions(editionsData.data);
    } catch (error) {
      console.error("Erro ao buscar edições:", error);
      setError("Erro ao buscar edições. Por favor, tente novamente mais tarde.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchInscriptionStatus = useCallback(async () => {
    try {
      const response = await service.getToggleInscription(); // Chamada à API para obter o status da inscrição
      setIsOpen(response.value); // Define isOpen com base no campo 'value' retornado
    } catch (error) {
      console.error("Erro ao obter status da inscrição:", error);
      openSnackbar("error", "Erro ao obter status da inscrição!");
    }
  }, []);

  const handleEditEdition = (edition) => {
    navigate(`/admin/edit-edition/${edition.id}`);
  };

  const handleDeleteClick = (edition) => {
    setEditionToDelete(edition);
    setConfirmDialogOpen(true);
  };

  const confirmDelete = async () => {
    const token = sessionStorage.getItem("token");
    try {
      await service.deleteEdition(editionToDelete.id, token);
      openSnackbar("success", "Edição deletada com sucesso!");
      fetchEditions();
    } catch (error) {
      console.error("Erro ao deletar edição:", error);
      openSnackbar("error", "Erro ao deletar edição!");
    }
  };

  const handleAddEdition = () => {
    navigate("/admin/add-edition");
  };

  const handleToggleInscription = async () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);

    try {
      await service.updateToggleInscription({ value: newIsOpen });
      openSnackbar("info", newIsOpen ? "Inscrições abertas!" : "Inscrições fechadas!");
    } catch (error) {
      console.error("Erro ao atualizar inscrição:", error);
      openSnackbar("error", "Erro ao atualizar inscrição!");
    }
  };

  useEffect(() => {
    fetchEditions();
    fetchInscriptionStatus(); // Chama a função para obter o status da inscrição
  }, [fetchEditions, fetchInscriptionStatus]);

  if (isLoading) {
    return <div>Carregando edições...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div>
      <DashboardHeader />
      <div className="manage-editions-container">
        <div className="manage-editions-card">
          <h1>Gerenciar Edições</h1>

          <div className="button-group">
            <button className="add-edition-button" onClick={handleAddEdition}>
              <AddIcon /> Adicionar Edição
            </button>
            <button
              className="send-content-button"
              onClick={handleToggleInscription}
            >
              <SendIcon /> {isOpen ? "Fechar inscrições" : "Abrir inscrições"}
            </button>
          </div>

          <input
            type="text"
            placeholder="Filtrar por título ou ano"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />

          <ul>
            {editions
              .filter((edition) =>
                edition.title
                  ? edition.title.toLowerCase().includes(filter.toLowerCase())
                  : edition.year.toString().includes(filter)
              )
              .map((edition) => (
                <li key={edition.id}>
                  {edition.title || `Edição do Ano ${edition.year}`}
                  <div className="action-icons">
                    <IconButton
                      onClick={() => handleEditEdition(edition)}
                      aria-label="edit"
                      size="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteClick(edition)}
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          confirmDelete();
          setConfirmDialogOpen(false);
        }}
        message={`Você realmente quer deletar a edição "${
          editionToDelete?.title || `do Ano ${editionToDelete?.year}`
        }"?`}
      />
    </div>
  );
}

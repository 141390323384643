import "./Sidebar.scss";
import logo from "./LOGO6.png";
import { useState, useEffect } from "react";
import Sub1 from "../Subnavs/Sub-1";
import Sub2 from "../Subnavs/Sub-2";
import Sub3 from "../Subnavs/Sub-3";
import Eye from "../Eye/Eye";
import { FiMenu, FiSearch } from "react-icons/fi";


function Sidebar(props) {
  const [subOn, showSub] = useState("show-0");
  const [currentSection, setCurrentSection] = useState(window.location.hash);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  function setPlace(position) {
    props.scrollToSection("primeiro", "pagina-vertical-1", position);
  }
  function setPlace2(position) {
    props.scrollToSection("seg", "pagina-vertical-2", position);
  }
  function setPlace3(position) {
    props.scrollToSection('ter', 'pagina-vertical-3', position);
  }
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      props.setPlaceOn("pesquisa");
      props.setValue(searchValue.toLowerCase());
      setSearchOpen(false);
    }
  }
  useEffect(() => {
    const handleHashChange = () => {
      setCurrentSection(window.location.hash);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("hashchange", handleHashChange);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="sidenav-wrapper">
    {isMobile ? (
      <div className="sidenav">
        <a href="#" id="logo" className="logo">
          <Eye size={24} />
        </a>

        <div className="icons">
          {!isSearchOpen ? (
            <FiSearch
              className="search-icon"
              onClick={() => setSearchOpen(true)} // Abre o campo de pesquisa
            />
          ) : (
            <input
              className="search-input"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus
              placeholder="Pesquisar..."
              onBlur={() => setSearchOpen(false)} // Fecha o campo ao perder foco
            />
          )}
          <FiMenu
            className="hamburger-icon"
            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          />
        </div>

        {isMobileMenuOpen && (
          <div className="mobile-menu">
            <a href="#sobre-nos"  onClick={() => {setPlace("sobre-nos"); setMobileMenuOpen(false);}}>
              Nosso rolê 
            </a>
            <a href="#historia" onClick={() => {setPlace2("historia"); setMobileMenuOpen(false);}}>
              Nossas memórias
            </a>
            <a href="#fortalecendo" onClick={() => {setPlace3("fortalecendo"); setMobileMenuOpen(false);}}>
              Chega mais
            </a>
          </div>
        )}
      </div>
      ) : (
        <div className="sidenav">
          <a
            className={
              props.placeOn === "pagina-vertical-3"
                ? "bg-azul color-b nav nav-3"
                : "nav nav-3"
            }
            id="prim-botao"
            onMouseLeave={() => showSub("show-0")}
            onMouseEnter={() => showSub("show-3")}
            onClick={() => props.scrollToSection("ter", "down-1")}
          >
            <span>Chega mais</span>
          </a>
          <a
            className={
              props.placeOn === "pagina-vertical-2"
                ? "bg-rosa nav nav-2"
                : "nav nav-2"
            }
            onMouseLeave={() => showSub("show-0")}
            onMouseEnter={() => showSub("show-2")}
            onClick={() => props.scrollToSection("seg", "down-1")}
          >
            <span>Nossas memórias</span>
          </a>
          <a
            className={
              props.placeOn === "pagina-vertical-1"
                ? "bg-verde color-b nav nav-1"
                : "nav nav-1"
            }
            onMouseLeave={() => showSub("show-0")}
            onMouseEnter={() => showSub("show-1")}
            onClick={() => props.scrollToSection("primeiro", "down-1")}
          >
            <span>Nosso rolê</span>
          </a>
          <a href="#" id="logo">
            <Eye />
          </a>
        </div>
      )}
      {!isMobile && (
        <div className="subnavs">
          <Sub1
            scrollToSection={props.scrollToSection}
            setSecondaryIconColor={props.setSecondaryIconColor}
            setSecondaryColor={props.setSecondaryColor}
            subOn={subOn}
            showSub={showSub}
            bg={props.bg}
            currentSection={currentSection.replace("#", "")}
            placeOn={props.placeOn}
          />
          <Sub2
            scrollToSection={props.scrollToSection}
            setSecondaryIconColor={props.setSecondaryIconColor}
            setSecondaryColor={props.setSecondaryColor}
            subOn={subOn}
            showSub={showSub}
            bg={props.bg}
            currentSection={currentSection.replace("#", "")}
            placeOn={props.placeOn}
          />
          <Sub3
            scrollToSection={props.scrollToSection}
            setSecondaryIconColor={props.setSecondaryIconColor}
            setSecondaryColor={props.setSecondaryColor}
            subOn={subOn}
            showSub={showSub}
            bg={props.bg}
            currentSection={currentSection.replace("#", "")}
            placeOn={props.placeOn}
          />
        </div>
      )}
    </div>
  );
}

export default Sidebar;

